import { getRandomName } from "Utils/Helper";
import Platform from 'react-platform-js'

// -----BASE_URL Code--------
export let BASE_URL = "https://devapi.chroniclecloud.com/";
// export let BASE_URL = "https://dev-mgt-api.chroniclecloud.com";

// export let AUTH_BASE_URL = "https://dev-mgt-api.chroniclecloud.com/";

export let AUTH_BASE_URL = "https://mgt-api.chroniclecloud.com/";

export let BASE_MICROSERVICE_URL_PLAN = "https://us-central01-gateway-api.chroniclecloud.com/"

// export let BASE_MICROSERVICE_URL = "https://dev-us-central01-gateway-api.chroniclecloud.com/"
export let BASE_MICROSERVICE_URL = sessionStorage.getItem("RegionalUrl")

// -----BASE_URL For Blog--------
export let BASE_URL_FOR_BLOG = "https://dev-wp.chroniclecloud.com/wp-json/wp/v2/posts";

// -----BASE_URL For Notify Email--------
export let BASE_URL_FOR_NOTIFY_EMAIL = "https://api.chroniclecloud.com/v1/api_notify_email";

// -----Chronicle Cloud Code--------
export const chroniclecloud_code = 'Y2hyb25pY2xldXNlcnxQYXNzd29yZCMxMDU=';


// -----Device Token by Firebase--------
export const device_token_id = '03df25c845d460bcdad7802d';

// -----Device Tye--------
export const device_type = 'web-app';

// -----Device Version--------
export const device_version = JSON.stringify(Platform.OS + " " + Platform.OSVersion);


// -----Device Ud Id Code--------
export const device_udid = getRandomName();

// -----FirebseStroageFolder--------
export const FirebseStroageFolder = 'rgnl';
export const firebaseBucket = "gs://us-central-01-bucket-prd"

// -----Folder Name for Firebase--------
export const FolderName = ''

// -----app_version--------
export const app_version = '3.17'

export const link_Url = 'https://dev-teachers.chroniclecloud.com/?token='

// -----Google Drive Keys--------
export const API_KEY = "AIzaSyC6BAB9CCNpb4wyLpRZ_3VRjkYAMmhT5Yg"
export const CLIENT_ID = "582101023673-cu52sq97cm9ur0gq1fka8du1rae9ktso.apps.googleusercontent.com"
export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
export const PROJECTID = '879480850061';
export const SCOPES = 'https://www.googleapis.com/auth/drive'
// -----Google Drive Keys--------

// -----Pay Pal key--------
export const PAYPALENV = 'production'; // defult 'sandbox. you can set here to 'production' for production
export const CURRENCY = 'USD'; // or you can set this value from your props or state
export const LOCALE = 'en_US';

export const PAYPALCLIENT = {
    // sandbox: 'AZOZ_M-Llc7HXxyA98CYt4TfLLX82bxOfdmIesicmi0nSRJIW1X8kmsVh53z6s3zxy3OWPdqniUxeRJy'
    production: 'AT06fC1p-ZG4psuCH1YNVgEoJ9fJtEKH3WKBlowSfeeg3vVhz-ULLAzC2Vnw0J9vT7x9KGA8-linFCNK',
}
// -----Pay Pal key--------


// -----Rendom Colour Array--------
export const Colour = [
    'red',
    'Green',
    'blue',
    'yellow',
    'black',
    'white'
]

// -----Rendom Colour Array--------
export const ColourCode = [
    "#777777", //gray
    "#FF4444",  //red
    "#23BF1D", //green
    "#FAB700", // yellow
    "#386CB5", //blue
    // "#FFFFFF"  //white

]
